const countries = [
    {
        name: 'Andorra',
        code: 'AD',
    },
    {
        name: 'Aruba',
        code: 'AW',
    },
    {
        name: 'Azerbaijan',
        code: 'AZ',
    },
    {
        name: 'Barbados',
        code: 'BB',
    },
    {
        name: 'Bangladesh',
        code: 'BD',
    },
    {
        name: 'Belgium',
        code: 'BE',
    },
    {
        name: 'Bahrain',
        code: 'BH',
    },
    {
        name: 'Brazil',
        code: 'BR',
    },
    {
        name: 'Bahamas ',
        code: 'BS',
    },
    {
        name: 'Canada',
        code: 'CA',
    },
    {
        name: 'Colombia',
        code: 'CO',
    },
    {
        name: 'Czechia',
        code: 'CZ',
    },
    {
        name: 'Spain',
        code: 'ES',
    },
    {
        name: 'France',
        code: 'FR',
    },
    {
        name: 'Guatemala',
        code: 'GT',
    },
    {
        name: 'India',
        code: 'IN',
    },
    {
        name: 'Mexico',
        code: 'MX',
    },
    {
        name: 'United States of America',
        code: 'US',
    },
    {
        name: 'Italy',
        code: 'IT',
    },
];

export default countries;
