'use strict';

import { view } from '@/functions';

const prefix = '/commercialpartners';
export default [
    {
        path: `${prefix}`,
        name: 'commercialpartners',
        meta: {
            scopes: ['read_accounts', 'write_accounts']
        },
        component: () => view('commercialpartners/index'),
    },
];