'use strict';
import { view } from '@/functions';

const prefix = '/bin-manager';
export default [
    {
        path: prefix,
        name: 'bin-manager',
        meta: {
            scopes: ['read_bin_manager'],
            profile: 'admin'
        },
        component: () => view('bin-manager/index'),
    },
    {
        path: `${prefix}/info`,
        name: 'bin-manager-info',
        meta: {
            scopes: ['read_bin_manager'],
            profile: 'admin'
        },
        component: () => view('bin-manager/info'),
    }
];
