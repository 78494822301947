<template>
    <div class="dashboard">
        <dashboard-header :open="openSidebar" @toggleSidebar="toggleSidebar" @logout="logout" @sidebar-button="toggleSidebar"/>
        <dashboard-sidebar :open="openSidebar" @toggleSidebar="toggleSidebar" @logout="logout" class="dashboard__sidebar"/>
        <div class="dashboard__content" :class="{ 'dashboard__content--open': openSidebar }">
            <div v-if="filterAccount && viewInfo" class="active-user-container">
                <div v-if="activeAccount && viewInfo">
                    <active-user :account="activeAccount" />
                </div>
            </div>
            <router-view v-if="hasPermission" :key="routerViewKey" />
            <error-state :error="error" v-else />
        </div>
        <auto-logout />
    </div>
</template>

<script>
import AutoLogout from '@/components/AutoLogout';
import DashboardHeader from '@/components/layouts/dashboard/Header';
import DashboardSidebar from '@/components/layouts/dashboard/Sidebar';
import { api } from '@/services';
import ErrorState from '@/components/ui/ErrorState.vue';
import ActiveUser from '@/layouts/ActiveUser.vue';
import { mapState, mapActions } from 'vuex';

export default {
    components: {
        AutoLogout,
        DashboardHeader,
        DashboardSidebar,
        ErrorState,
        ActiveUser,
    },
    data() {
        return {
            openSidebar: true,
            windowWidth: 0,
            hasPermission: true,
            error: {},
            routerViewKey: this.$route.fullPath,
            filterAccount: false,
            activeAccount: [],
            viewInfo: false,
        };
    },
    computed: {
        ...mapState(['globalFilters']),
    },
    watch: {
        globalFilters: {
            handler(newVal) {
                if (newVal.account !== 0) {
                    this.activeAccount = newVal.account;
                    this.filterAccount = true;
                    this.viewInfo = true;
                    if (this.$route.name === "accounts-info" || this.$route.name === "accounts") {
                        this.$router.replace({ 
                            name: 'accounts-info',
                            query: { id: this.globalFilters.account.id } 
                        });
                    } else {
                        this.routerViewKey = Date.now();
                    }
                } else {
                    this.activeAccount = [];
                    this.filterAccount = false;
                    if (this.$route.name != "accounts-info") {
                        this.routerViewKey = Date.now();
                    }
                }
            },
            deep: true,
        },
        $route(to) {
            this.checkInfo();
            window.addEventListener("resize", this.getWindowWidth)
            if (this.$route.meta.scopes) {
                this.checkPermissions();
            } else {
                this.hasPermission = true;
            }
        },
        '$route.query.id': function (newId, oldId) {
            if (newId !== oldId) {
            this.routerViewKey = Date.now();
            }
        }
    },
    async mounted() {
        await this.$store.dispatch('accounts/getAccount')
        this.checkInfo();
        this.checkGlobalFilters();
        this.getWindowWidth();
        this.checkPermissions();
        window.addEventListener("resize", this.getWindowWidth);
    },
    methods: {
        ...mapActions({ setAccount: 'globalFilters/setAccount' }),
        toggleSidebar() {
            this.openSidebar = !this.openSidebar;
        },
        getWindowWidth() {
            this.windowWidth = window.innerWidth;
            this.openSidebar = this.windowWidth > 992;
        },
        async logout() {
            try {
                this.setAccount(0);
                await api.logout();
                this._deleteCookie("_tid");
                this.$router.push("/login");
            } catch (error) {
                console.error(error);
            }
        },
        checkPermissions() {
            let permissions = false;
            if(this.CAN(this.$route.meta.scopes, this.$route.meta.profile)) {
                this.hasPermission = !permissions;
            } else {
                this.hasPermission = permissions;
            }

            if(!this.hasPermission) {
                this.error = {
                    status: 403,
                    error: "Forbidden",
                    message: 'You do not have permission to access this page.'
                }
            }
        },
        async checkGlobalFilters() {
            try {
                if (this.globalFilters.account !== 0) {
                    this.activeAccount = this.globalFilters.account;
                    this.filterAccount = true;
                    this.checkInfo()
                } else {
                    this.activeAccount = [];
                    this.routerViewKey = this.$route.fullPath;
                    this.filterAccount = false;
                }
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            }
        },
        checkInfo() {
            if (this.$route.name === "accounts-info") {
                this.viewInfo = false;
            } else {
                this.viewInfo = true;
            }
        },
        clearAccount() {
            this.setAccount(0);
        },
    },
};
</script>

<style lang="scss">
.dashboard {
    width: 100%;

    &__content {
        float: right;
        margin-top: 65px;
        min-height: calc(100vh - 65px);
        width: calc(100% - 70px);
        box-sizing: border-box;
        transition: 300ms all;
        padding: 25px 30px;

        @media (max-width: 992px) {
            padding: 20px 15px;
            width: 100%;
        }

        @media (max-width: 768px) {
            width: 100%;
            padding: 20px 15px;
        }

        &--open {
            width: calc(100% - 250px);

            @media (max-width: 768px) {
                width: 100%;
            }
        }
    }
}
.active-user-container {
    display: flex;
    align-items: center;

    .delete-button {
        margin-left: 10px;
        cursor: pointer;
    }
}
.chargebacks-list, .activitys-list {
    &__date-range-picker {
        align-items: center;
        background-color: $general-white;
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        display: flex;
        gap: 8px;
        height: 40px;
        padding: 4.5px 8px;
        width: 100%;

        .caret {
            margin-left: auto;

            &::before {
                color: $ecart-accent;
                content: '\f078';
                font-family: 'Font Awesome 5 Free', sans-serif;
                font-size: 16px;
                line-height: 1;
            }
        }
    }

    .vue-daterange-picker {
        width: 100%;
    }
}
</style>
