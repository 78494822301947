'use strict';

import { view } from '@/functions';
const prefix = '/affiliations';
export default [
    {
            path: prefix,
            name: 'affiliations',
            meta: {
                scopes: ['read_affiliations', 'write_affiliations']
            },
            component: () => view('affiliations/index')
        },
]
