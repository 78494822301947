const state = () => ({
    account: null,
    scopes: [],
});

const actions = {
    async getAccount({ commit }) {
        const { data } = await this.$axios.get('/_/admins/me');

        const scopes = data.scopes?.map((scope) => scope);
        commit('setScopes', scopes);

        commit('setAccount', data);
    },
};

const mutations = {
    setAccount(state, data) {
        state.account = data;
    },

    setScopes(state, scopes) {
        state.scopes = scopes;
    }
};

const getters = {
    fullName(state) {
        if (!state.account) return '';
        const firstName = state.account.first_name || '';
        const lastName = state.account.last_name || '';

        return `${firstName.toUpperCase()} ${lastName.toUpperCase()}`;
    },
    hasScopes(state) {
        if(!state.scopes) return [];
        return state.scopes;
    },
    hisRole(state) {
        return state.account?.role || '';
    },
    chartToken(state) {
        return state.account?.mongo?.chart_token || '';
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
