'use strict';

import cookies from '@/mixins/cookies.mixin';
import { createRouter, createWebHistory } from 'vue-router';

import accounts from './accounts';
import admins from './admins';
import chargebacks from './chargebacks';
import dashboard from './dashboard';
import invitation from './invitation';
import main from './main';
import payouts from './payouts';
import leads from './leads';
import reports from './reports';
import transactions from './transactions';
import withdrawals from './withdrawals';
import activitys from './activitys';
import refunds from './refunds';
import risk from './risks';
import webhookLogs from './webhook-logs';
import activations from './activations';
import twofactorauth from './twofactorauth';
import invoices from './invoices';
import rules from './rules';
import profiles from './profiles';
import whitelist from './whitelist';
import transfers from './transfers';
import BinManager from './bin-manager';  
import partners from './partners';
import commercialpartners from './commercialpartners';
import affiliations from './affiliations';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        ...accounts,
        ...admins,
        ...chargebacks,
        ...dashboard,
        ...invitation,
        ...main,
        ...payouts,
        ...leads,
        ...transactions,
        ...reports,
        ...withdrawals,  
        ...activitys,
        ...refunds,
        ...risk,
        ...webhookLogs,
        ...activations,
        ...twofactorauth,
        ...invoices,
        ...rules,
        ...whitelist,
        ...profiles,
        ...transfers,
        ...BinManager,
        ...partners,
        ...commercialpartners,
        ...affiliations
    ]
});

router.beforeEach((to, from, next) => {
    const access_token = cookies.methods._getCookie('_tid');
    const twofactorauth = cookies.methods._getCookie('_tfa');
    const expired_password = cookies.methods._getCookie('_pexp');
    const public_routes = ['update-password', 'invitation-sign-up','recovery-password'];
    const auth_route = ['register-auth', 'two-step-auth', 'expired-password'];
    if (public_routes.includes(to.name)) {
        return next();
    }

    if(auth_route.includes(to.name) && access_token){
        return next();
    }

    if(to.name === 'login' && access_token && twofactorauth && !expired_password){
        next({ name: 'accounts' });
    } else if(to.name !== 'login' && !access_token && !twofactorauth) {
        next({ name: 'login' });
    } else if((to.name !== 'two-step-auth' || to.name !== 'register-auth')  && access_token && !twofactorauth){
        cookies.methods._deleteCookie('_tid');
        next({ name: 'login'});
    } else if(to.name !== 'expired-password'  && access_token && twofactorauth && expired_password){
        cookies.methods._deleteCookie('_tid');
        cookies.methods._deleteCookie('_tfa');
        cookies.methods._deleteCookie('_pexp');
        next({ name: 'login'});
    } else {
        next();
    }
});

export default router;